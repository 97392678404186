export const DOCS_LINKS = {
  table: {
    api: 'https://docs.hydrolix.io/reference/list-tables',
    doc: 'https://docs.hydrolix.io/docs/projects-and-tables#tables',
    lifecycleSettings: {
      ageAndReaper: 'https://docs.hydrolix.io/docs/age#age-vs-reaper',
      maxFutureDays: 'https://docs.hydrolix.io/docs/additional-table-settings#max-future-days',
    },
    indexSettings: {
      sortKey: 'https://docs.hydrolix.io/docs/additional-table-settings#sorting',
      shardKey: 'https://docs.hydrolix.io/docs/additional-table-settings#shard-key',
    },
    mergeSettings: {
      enabledFlag: 'https://docs.hydrolix.io/docs/merge#enabling-merge-on-tables',
    },
    partitionSettings: {
      streamIngest: 'https://docs.hydrolix.io/docs/streaming-table-configuration-options',
    },
    streamAuthSettings: {
      streamAuth:
        'https://docs.hydrolix.io/docs/streaming-table-configuration-options#configuring-stream-authentication-settings',
    },
    summarySettings: {
      settings: 'https://docs.hydrolix.io/docs/summary-tables-aggregation',
    },
    bucketSettings: {
      storageMap:
        'https://docs.hydrolix.io/docs/store-data-across-multiple-buckets#storage-mapping',
    },
  },
  dictionary: {
    api: 'https://docs.hydrolix.io/reference/get-dictionary-input-formats',
    doc: 'https://docs.hydrolix.io/docs/dictionaries-user-defined-functions',
  },
  function: {
    api: 'https://docs.hydrolix.io/reference/list-functions',
    doc: 'https://docs.hydrolix.io/docs/custom-functions',
  },
  file: {
    api: 'https://docs.hydrolix.io/reference/upload-file',
    doc: 'https://docs.hydrolix.io/docs/dictionaries-user-defined-functions',
  },
  alterJob: {
    api: 'https://docs.hydrolix.io/reference/list-alter-jobs',
    doc: 'https://docs.hydrolix.io/docs/alter-table',
  },
  batchJob: {
    api: 'https://docs.hydrolix.io/reference/list-batch-jobs',
    doc: 'https://docs.hydrolix.io/docs/ingest-batch',
    settings: 'https://docs.hydrolix.io/docs/ingest-batch#the-settings-object',
    regexFilter: 'https://docs.hydrolix.io/docs/ingest-batch#regex-filter',
  },
  query: {
    api: 'https://docs.hydrolix.io/reference/query-data-post',
    doc: 'https://docs.hydrolix.io/docs/query-interfaces',
    options: {
      outputFormatting: 'https://docs.hydrolix.io/docs/query-api-http-options#output-formatting',
      circuitBreakers: 'https://docs.hydrolix.io/docs/query-circuit-breakers#circuit-breakers',
      rateLimiting: 'https://docs.hydrolix.io/docs/query-circuit-breakers#rate-limiting',
      otherFlags: 'https://docs.hydrolix.io/docs/query-circuit-breakers#other-flags',
    },
  },
  scale: {
    api: 'https://docs.hydrolix.io/reference/list-pools',
    doc: 'https://docs.hydrolix.io/docs/k8s-scaling-on',
  },
  user: {
    api: 'https://docs.hydrolix.io/reference/update-table-access',
    doc: 'https://docs.hydrolix.io/docs/rbac',
  },
  role: {
    doc: 'https://docs.hydrolix.io/docs/rbac#roles',
  },
  source: {
    api: 'https://docs.hydrolix.io/reference/list-kafka-sources',
    doc: 'https://docs.hydrolix.io/docs/streaming-ingest',
    kafka: {
      api: 'https://docs.hydrolix.io/reference/list-kafka-sources',
      doc: 'https://docs.hydrolix.io/docs/kafka',
      settingsDocs: 'https://docs.hydrolix.io/docs/kafka',
      settingsApi: 'https://docs.hydrolix.io/reference/list-kafka-sources',
    },
    kinesis: {
      api: 'https://docs.hydrolix.io/reference/list-kinesis-sources',
      doc: 'https://docs.hydrolix.io/docs/ingest-aws-kinesis',
      advancedDoc: 'https://docs.hydrolix.io/docs/ingest-kinesis',
      advancedApi: 'https://docs.hydrolix.io/reference/list-kinesis-sources',
    },
    autoBatch: {
      api: 'https://docs.hydrolix.io/reference/patch-table',
      doc: 'https://docs.hydrolix.io/docs/streaming-table-configuration-options',
      settings: 'https://docs.hydrolix.io/docs/gcp-storage-notify-aka-gcp-auto-ingest',
    },
    summary: {
      api: 'https://docs.hydrolix.io/reference/list-summary-sources',
      doc: 'https://docs.hydrolix.io/docs/summary-table',
    },
  },
  transform: {
    api: 'https://docs.hydrolix.io/reference/list-transforms',
    doc: 'https://docs.hydrolix.io/docs/transforms-and-write-schema',
    settings: 'https://docs.hydrolix.io/docs/transform-structure#settings',
    formatOptions: 'https://docs.hydrolix.io/docs/format-options',
    outputColumn: 'https://docs.hydrolix.io/docs/transform-structure#settings-output-columns',
  },
  systemHealth: {
    api: 'https://docs.hydrolix.io/reference/query-data-post',
    doc: 'https://docs.hydrolix.io/docs/system-health',
  },
  policies: {
    doc: 'https://docs.hydrolix.io/docs/rbac#policies',
  },
};
