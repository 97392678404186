import { useRouter } from 'next/router';

import { useTranslation } from 'src/hooks/translation';
import * as UI from 'styles/sidebar';
import { DOCS_LINKS } from 'utils/constants/docsLinks';
import { ROUTES } from 'utils/constants/routes';

const NavItemDocSection = ({ isOpen }: { isOpen: boolean }) => {
  const t = useTranslation();
  const { pathname, asPath } = useRouter();

  const docLinks = [
    {
      api: DOCS_LINKS.table.api,
      documentation: DOCS_LINKS.table.doc,
      route: [
        ROUTES.dataTables,
        ROUTES.createDataTable,
        ROUTES.createSummaryTable,
        '/data/tables/[tableId]',
      ],
      topic: t.sidebar.pageDocs.table,
      test: 'table',
    },
    {
      api: DOCS_LINKS.dictionary.api,
      documentation: DOCS_LINKS.dictionary.doc,
      route: [
        ROUTES.dataDictionaries,
        ROUTES.createDataDictionaries,
        ROUTES.editDictionary('[projectId]', '[dictionaryId]'),
      ],
      topic: t.sidebar.pageDocs.dictionary,
      test: 'dictionary',
    },
    {
      api: DOCS_LINKS.function.api,
      documentation: DOCS_LINKS.function.doc,
      route: [
        ROUTES.dataFunctions,
        ROUTES.createDataFunctions,
        ROUTES.editFunction('[projectId]', '[functionId]'),
      ],
      topic: t.sidebar.pageDocs.function,
      test: 'function',
    },
    {
      api: DOCS_LINKS.file.api,
      documentation: DOCS_LINKS.file.doc,
      route: [ROUTES.dataFiles, ROUTES.createDataFile, ROUTES.editFile('[projectId]', '[name]')],
      topic: t.sidebar.pageDocs.file,
      test: 'file',
    },
    {
      api: DOCS_LINKS.alterJob.api,
      documentation: DOCS_LINKS.alterJob.doc,
      route: [ROUTES.alterTables, ROUTES.alterNew],
      topic: t.sidebar.pageDocs.alter,
      test: 'alter',
    },
    {
      api: DOCS_LINKS.batchJob.api,
      documentation: DOCS_LINKS.batchJob.doc,
      route: [ROUTES.batchTable, ROUTES.batchNew],
      topic: t.sidebar.pageDocs.batch,
      test: 'batch',
    },
    {
      api: DOCS_LINKS.query.api,
      documentation: DOCS_LINKS.query.doc,
      route: [ROUTES.query, ROUTES.queryReference(), ROUTES.queryResult, ROUTES.queryStatistics],
      topic: t.sidebar.pageDocs.query,
      test: 'query',
    },
    {
      api: DOCS_LINKS.scale.api,
      documentation: DOCS_LINKS.scale.doc,
      route: [
        ROUTES.scaleTables,
        ROUTES.scaleSearchTable,
        ROUTES.scaleCore,
        ROUTES.scalePool,
        ROUTES.scalePoolEdit('[poolId]'),
      ],
      topic: t.sidebar.pageDocs.scale,
      test: 'scale',
    },
    {
      api: DOCS_LINKS.user.api,
      documentation: DOCS_LINKS.user.doc,
      route: [ROUTES.users, ROUTES.createUser, ROUTES.editUser('[userId]')],
      topic: t.sidebar.pageDocs.user,
      test: 'user',
    },
    {
      api: DOCS_LINKS.user.api,
      documentation: DOCS_LINKS.role.doc,
      route: [ROUTES.roles, ROUTES.createRole, ROUTES.editRole('[roleId]')],
      topic: t.sidebar.pageDocs.role,
      test: 'roles',
    },
    {
      api: DOCS_LINKS.source.api,
      documentation: DOCS_LINKS.source.doc,
      route: [ROUTES.createIngestSource],
      topic: t.sidebar.pageDocs.source,
      test: 'source',
    },
    {
      api: DOCS_LINKS.source.kafka.api,
      documentation: DOCS_LINKS.source.kafka.doc,
      routeString: 'source/edit/kafka',
      topic: t.sidebar.pageDocs.kafka,
      test: 'kafka',
    },
    {
      api: DOCS_LINKS.source.kinesis.api,
      documentation: DOCS_LINKS.source.kinesis.doc,
      routeString: 'source/edit/kinesis',
      topic: t.sidebar.pageDocs.kinesis,
      test: 'kinesis',
    },
    {
      api: DOCS_LINKS.source.autoBatch.api,
      documentation: DOCS_LINKS.source.autoBatch.doc,
      routeString: 'source/edit/autoBatch',
      topic: t.sidebar.pageDocs.autoBatch,
      test: 'autoBatch',
    },
    {
      api: DOCS_LINKS.transform.api,
      documentation: DOCS_LINKS.transform.doc,
      route: [
        ROUTES.editTransform('[tableId]', '[transformId]'),
        ROUTES.createTransform,
        ROUTES.validateTransform,
      ],
      topic: t.sidebar.pageDocs.transform,
      test: 'transform',
    },
    {
      api: DOCS_LINKS.systemHealth.api,
      documentation: DOCS_LINKS.systemHealth.doc,
      route: [ROUTES.systemHealthCounts, ROUTES.systemHealthLogs],
      topic: t.sidebar.pageDocs.systemHealth,
      test: 'systemHealth',
    },
  ];

  const hrefElement = docLinks.find((element) => {
    if (!!element?.route) {
      return element?.route?.includes(pathname);
    }
    if (!!element?.routeString) {
      return asPath.includes(element?.routeString);
    }

    return null;
  });

  const handleClick = (str: string) => {
    window.open(str);
  };

  return !!hrefElement ? (
    <>
      <UI.NavBreak />
      <UI.NavList data-testid={`sidebar-doc-links-${hrefElement.test}`}>
        <UI.NavItemDoc onClick={() => handleClick(hrefElement.documentation)} as="span">
          <UI.Name $isOpen={!!isOpen}>{`${hrefElement.topic} Docs`}</UI.Name>
        </UI.NavItemDoc>
        <UI.NavItemDoc onClick={() => handleClick(hrefElement.api)} as="span">
          <UI.Name $isOpen={!!isOpen}>{`${hrefElement.topic} API`}</UI.Name>
        </UI.NavItemDoc>
      </UI.NavList>
    </>
  ) : null;
};

export default NavItemDocSection;
